@tailwind base;
@tailwind components;
@tailwind utilities;

/* Inkluder Diatype Light fonten */
@font-face {
  font-family: "Diatype Light";
  src:
    url("./fonts/ABCDiatype-Light.otf") format("woff2"),
    /* oppdater denne URL-en med riktig sti til fonten din */
      url("./fonts/ABCDiatype-Light.otf") format("woff"); /* oppdater denne URL-en med riktig sti til fonten din */
  font-weight: normal;
  font-style: normal;
}

/* Global font-styling */
body {
  font-family: "Diatype Light", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.moving-div {
  animation: moveDown 5s ease-in-out infinite alternate;
}

@keyframes moveDown {
  0% {
    transform: translateY(0%);
  }
  70% {
    transform: translateY(calc(94vh - 60%));
  }
}

/* Hide spinner controls in number inputs */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Hide controls in Firefox */
}

@layer components {
  .fiksetekst1 {
    @apply border-b border-black p-6 text-xl;
  }

  .fiksetekst2 {
    @apply fiksetekst1 text-gray-500;
  }

  .fikseBox {
    @apply flex w-full border-b border-black bg-white p-4 pl-6 text-black;
  }
  .fikseButton {
    @apply fikseBox hover:bg-gray-100;
  }
  .noBorder {
    @apply border-b border-none;
  }
}
